import { autorun, computed, configure, makeAutoObservable, toJS } from 'mobx';
import {
    OptionsVariationsType,
    ProductEntity,
    ProductType,
    VariationType,
} from './types';
import { eventsService } from 'client/widget-components/services/events-service/eventsService';
import { decodeUniqueKey, encodeUniqueKey } from './utils';

configure({ isolateGlobalState: true });
export const DEFAULT_VARIATION_ID = 'defvar12';

export class ProductState {
    product: ProductType;
    dropdownOptions: OptionsVariationsType = {};
    variations: Map<string, VariationType> = new Map();
    selectedVariationKey: string = '';

    constructor(product: ProductType) {
        makeAutoObservable(this, {
            selectedVariation: computed,
            productData: computed,
        });
        this.product = product;
        this.dropdownOptions = product.options;
        this.initVariations(product.variations);
    }

    initVariations(variations: VariationType[] = []) {
        variations.forEach((variation) => {
            const optionValues = variation.selected_options_values;
            if (optionValues) {
                this.variations.set(encodeUniqueKey(optionValues), variation);
            }
        });
        // init default values
        if (!this.selectedVariationKey) {
            const validVariation = variations.find(
                (variant) => variant.selected_options_values
            )?.selected_options_values;
            if (validVariation) {
                const variationObj = JSON.parse(validVariation);
                this.selectedVariationKey = encodeUniqueKey(variationObj);
            }
        }
    }

    getSelectedField(key: string) {
        const fieldValue = decodeUniqueKey(this.selectedVariationKey)[key];
        if (fieldValue && this.dropdownOptions[key]) {
            return this.dropdownOptions[key].find(
                (op) => op.value === fieldValue
            );
        }
    }

    get selectedVariation() {
        return this.variations.get(this.selectedVariationKey);
    }

    // Merges product & current variation data
    get productData(): ProductEntity {
        const selectedVariation = toJS(this.selectedVariation);
        const itemId = this.getItemId(selectedVariation);
        if (selectedVariation) {
            const image =
                selectedVariation.images[0]?.image || this.product.image;

            return {
                ...this.product,
                ...selectedVariation,
                image,
                itemId,
            };
        }
        return {
            ...this.product,
            itemId,
        };
    }

    updateVariation(variationName: string, variationValue: string) {
        if (this.selectedVariation?.selected_options_values) {
            const optionValues = JSON.parse(
                this.selectedVariation.selected_options_values
            );
            this.selectedVariationKey = encodeUniqueKey({
                ...optionValues,
                [variationName]: variationValue,
            });
        }
    }

    onSelectedValuesChange(
        callback: (selectedVariation?: ProductEntity) => void
    ) {
        const imageToIndex = this.product.images.reduce(
            (result: { [key: string]: number }, image, index) => ({
                ...result,
                [image.image]: index,
            }),
            {}
        );
        autorun(() => {
            const variationImage = this.productData.image;
            const newIndex = imageToIndex[variationImage];
            eventsService.dispatch(
                'selected-image-changed',
                'dynamic_page_collection.images',
                { newIndex, newSrc: variationImage }
            );
            callback(this.productData);
        });
    }

    private getItemId(currentVariation?: VariationType) {
        const productId = this.product.identifier || this.product.sku;
        const productExternalId = this.product.external_id || '';
        const {
            identifier: variationId = DEFAULT_VARIATION_ID,
            external_id: variationExternalId = '',
        } = currentVariation || this.defaultVariation || {};

        return `${productId}_${variationId}:${productExternalId}_${variationExternalId}`;
    }

    /**
     * Get the variation which represents the product without selected choices
     */
    private get defaultVariation() {
        return this.product.variations.find(
            (variation) => !variation.selected_options_values
        );
    }
}
