import { MouseEvent } from 'react';
import { context } from '@shared-services/context-service';
import { CustomField } from 'client/widget-components/ecom/types';
import { ProductEntity } from 'client/widget-components/ecom/stores/types';
import { isObjectEmpty } from 'client/utils';

export type ComponentProps = {
    isDisabled: boolean;
};

export const useAddToCart = (product: ProductEntity) => {
    if (isObjectEmpty(product)) {
        return { elementAttributes: {}, componentProps: {} };
    }
    const {
        itemId,
        name,
        price,
        image,
        selected_options_values,
        internal_quantity,
        shippable,
    } = product;

    const quantityEnabled = !!internal_quantity || internal_quantity === 0;
    const shouldDisable = quantityEnabled && internal_quantity! <= 0;

    const onClick = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (!context.isEditor && window.Snipcart && !shouldDisable) {
            const addItemToCart = window.Snipcart.api.cart.items.add;
            addItemToCart({
                id: itemId,
                price,
                name,
                image,
                quantity: 1,
                url: window.location.href, // Ignored by Snipcart but needed for validation
                shippable,
                stackable: true,
                taxable: true,
                customFields: getSnipcartCustomFields(),
                maxQuantity: quantityEnabled ? internal_quantity : undefined,
            });
        }
    };

    const getSnipcartCustomFields = () => {
        if (!selected_options_values) {
            return;
        }
        const selectedOptions: { [key: string]: string } = JSON.parse(
            selected_options_values
        );
        const customFields: CustomField[] = Object.entries(selectedOptions).map(
            ([key, option]) => ({
                value: option,
                name: key,
                type: 'readonly',
            })
        );

        return customFields;
    };

    const elementAttributes = {
        'data-item-id': itemId,
        'data-item-price': price,
        'data-auto': 'addToCart',
        className: 'snipcart-add-item',
        onClick,
    };

    const componentProps: ComponentProps = {
        isDisabled: shouldDisable,
    };

    return { elementAttributes, componentProps };
};
