import { FC } from 'react';

export enum WidgetType {
    Slider = 'SSR_IMAGE_SLIDER',
    Cart = 'SSR_CART',
    AddToCart = 'SSR_ADD_TO_CART',
    OptionsVariations = 'SSR_OPTIONS_VARIATIONS',
    Breadcrumbs = 'SSR_BREADCRUMBS',
    CollectionSearch = 'SSR_COLLECTION_SEARCH',
    RuntimeFilterSort = 'SSR_FILTER_SORT',
    ProductPrice = 'SSR_PRODUCT_PRICE',
}

export type RuntimeWidget<T = any> = FC<T> & {
    skipHydration?: boolean;
};

export interface CollectionsAPI {
    storeData: (name: string) => CollectionsAPI;
    pageSize: (value: number) => CollectionsAPI;
    pageNumber: (value: number) => CollectionsAPI;
    search: (value: string) => CollectionsAPI;
    get: () => {
        values: Array<object>;
        page: {
            pageNumber: number;
            totalPages: number;
        };
    };
}
