import React, { ComponentType } from 'react';
import { useAddToCart, ComponentProps } from './useAddToCart';
import { ecomObserver } from '@shared-services/ecom-service';
import { ProductEntity } from 'client/widget-components/ecom/stores/types';
import styled from 'styled-components';

interface AddToCartProps extends Record<string, unknown> {
    productData: ProductEntity;
}

const ComponentWrapper = styled.div`
    cursor: auto;
`;

export default function withAddToCart<
    PureCompProps extends ComponentProps & Record<string, unknown>, // Pure Component's props - should handle all keys from ComponentProps
    WrappedCompProps extends AddToCartProps &
        Omit<PureCompProps, keyof ComponentProps> // HoC output component props - (Pure Component's props + AddToCartProps - ComponentProps)
>(WrappedComponent: ComponentType<PureCompProps>) {
    return ecomObserver((allProps: WrappedCompProps) => {
        const { productData, ...props } = allProps;
        const { elementAttributes, componentProps } = useAddToCart(productData);
        if (!productData) {
            return <div />;
        }
        return (
            <ComponentWrapper {...elementAttributes}>
                <WrappedComponent
                    {...(props as PureCompProps)}
                    {...componentProps}
                />
            </ComponentWrapper>
        );
    });
}
