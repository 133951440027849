import React, { FC, useState, useEffect } from 'react';
import { TransitionType } from 'client/widget-components';
import SliderLayoutView from './sliderView/SliderLayoutView';
import { PaginationPlacement, RuntimeSliderProps } from './sliderCommonTypes';
import { sliderlayoutTypeToDesignData } from './sliderLayouts';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { Placeholder } from 'client/widget-components/components/Placeholder';
import { SliderLayoutViewForComputedDesign } from './sliderView/SliderLayoutViewForComputedDesign';
import { useEditorContext } from 'client/infra/renderers/editorState';

import { Div } from 'client/widget-components/basicComponents';
import { featureFlags } from '@shared-services/feature-flags';
import { eventsService } from 'client/widget-components/services/events-service/eventsService';

export const widgetConfig = {
    PaginationPlacement,
};

const defaultProps = {
    selectedIndex: 0,
    autoPaginationInterval: 3000,
    transitionDuration: 1,
    transitionType: TransitionType.slideFromRight,
    shouldShowImages: true,
    shouldShowPaginationOnHover: false,
    shouldEnlargeImageOnClick: true,
};

const RuntimeSlider: FC<RuntimeSliderProps> = ({
    layout = 'LAYOUT_1',
    _styles,
    slidesData,
    bindingSource,
    slotsInFrame,
    ...otherProps
}) => {
    const layoutDesignData = sliderlayoutTypeToDesignData[layout];
    const { styles: layoutStyles } = layoutDesignData;
    const styles = {
        container: [layoutStyles.container, _styles?.container],
        slidesContainer: layoutStyles.slidesContainer,
        slide: {
            container: [layoutStyles.slide_container, _styles?.slide_container],
            button: {
                root: [
                    layoutStyles.slide_button?.root,
                    _styles?.slide_button?.root,
                ],
                text: [
                    layoutStyles.slide_button?.text,
                    _styles?.slide_button?.text,
                ],
                rootHover: [
                    layoutStyles.slide_button?.rootHover,
                    _styles?.slide_button?.rootHover,
                ],
                rootHoverText: [
                    layoutStyles.slide_button?.rootHoverText,
                    _styles?.slide_button?.rootHoverText,
                ],
                buttonTypeCSSClass: _styles?.slide_button?.buttonTypeCSSClass,
            },
            title: [layoutStyles.slide_title, _styles?.slide_title],
            mediaContainer: [
                layoutStyles.slide_mediaContainer,
                _styles?.slide_mediaContainer,
            ],
            contentContainer: [
                layoutStyles.slide_contentContainer,
                _styles?.slide_contentContainer,
            ],
            desc: [layoutStyles.slide_desc, _styles?.slide_desc],
            media: [layoutStyles.slide_media, _styles?.slide_media],
            overlay: [layoutStyles.slide_overlay, _styles?.slide_overlay],
        },
        pagination: {
            container: [
                layoutStyles.pagination_container,
                _styles?.pagination_container,
            ],
            buttonArrow: [
                layoutStyles.pagination_buttonArrow,
                _styles?.pagination_buttonArrow,
            ],
            buttonBullet: [
                layoutStyles.pagination_buttonBullet,
                _styles?.pagination_buttonBullet,
            ],
            buttonBulletActive: [
                layoutStyles.pagination_buttonBulletActive,
                _styles?.pagination_buttonBulletActive,
            ],
            thumbsContainer: [
                layoutStyles.pagination_thumbsContainer,
                _styles?.pagination_thumbsContainer,
            ],
            buttonThumb: [
                layoutStyles.pagination_buttonThumb,
                _styles?.pagination_buttonThumb,
            ],
            buttonThumbActive: [
                layoutStyles.pagination_buttonThumbActive,
                _styles?.pagination_buttonThumbActive,
            ],
        },
    };
    const [indexFromEvent, setIndexFromEvent] = useState<number>();

    useEffect(() => {
        if (!bindingSource) {
            return;
        }
        return eventsService.listen(
            'selected-image-changed',
            bindingSource,
            (event) => setIndexFromEvent(event.detail.newIndex)
        );
    }, [bindingSource, setIndexFromEvent]);

    const sliderLayoutViewPropsBase = {
        ...otherProps,
        ...layoutDesignData,
        styles,
        selectedIndex: indexFromEvent ?? otherProps.selectedIndex,
    };

    const { isInEditor } = useEditorContext();

    return (
        <Div style={{ height: '100%', overflow: 'hidden' }}>
            {slidesData.length === 0 ? (
                <Placeholder />
            ) : (
                <SliderLayoutView
                    {...sliderLayoutViewPropsBase}
                    paginationShow={otherProps.paginationShow}
                    slidesData={slidesData}
                    autoPagination={
                        otherProps.autoPagination && {
                            ...otherProps.autoPagination,
                            on:
                                otherProps.overrideAutoPaginationOn ??
                                otherProps.autoPagination?.on,
                        }
                    }
                    dataAuto='actual-slider'
                    slotsInFrame={
                        layoutDesignData.strictSlotsInFrame ??
                        slotsInFrame ??
                        layoutDesignData.defaultSlotsInFrame ??
                        1
                    }
                />
            )}
            {isInEditor && (
                <SliderLayoutViewForComputedDesign
                    {...sliderLayoutViewPropsBase}
                />
            )}
        </Div>
    );
};

RuntimeSlider.defaultProps = defaultProps;

const BoundedSlider = withErrorBoundary({
    Comp: RuntimeSlider,
    componentName: 'RuntimeSlider',
    logProps: true,
});

const RuntimeSliderWidget = (props: RuntimeSliderProps) => {
    /**
     * @todo
     * TODO: Remove after release
     */
    useEffect(() => {
        if (featureFlags.getBoolean('runtime.ssr.slider-dev-logging', false)) {
            console.debug({
                desc: 'RuntimeSliderWidget just rendered',
                props,
            });
        }
    });
    return <BoundedSlider {...props} />;
};

export default RuntimeSliderWidget;
