import React from 'react';
import Dropdown from './Dropdown/Dropdown';
import NativeDropdown from './NativeDropdown';
import { type DropdownProps } from './types';
import styled, { CSSProperties } from 'styled-components';
import cssVars from '../../cssVariables';
import ArrowIcon from './ArrowIcon';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
    SPECIFICITY_WRAP,
} from '../../responsive-styles/deprecated/styled-components-utils';

interface ComponentProps extends DropdownProps {
    isNativeComponent?: boolean;
    title?: string;
    className?: string;
    dropdownPlaceholder?: string;
    styles?: {
        title?: DeprecatedResponsiveStyles | CSSProperties;
        fields?: DeprecatedResponsiveStyles | CSSProperties;
    };
}

export default ({
    isNativeComponent = false,
    className,
    title,
    dropdownPlaceholder,
    styles,
    ...dropdownProps
}: ComponentProps) => {
    const Component = isNativeComponent ? NativeDropdown : Dropdown;
    return (
        <StyledDropdown
            data-auto='dropdown-wrapper'
            className={className}
            styles={styles || {}}
        >
            {title && (
                <h5 data-auto='dropdown-title' className='dropdown-title'>
                    {title}
                </h5>
            )}
            <div className='dropdown-component'>
                <Component
                    className='dropdown'
                    placeholder={dropdownPlaceholder}
                    {...dropdownProps}
                />
                <ArrowIcon color={cssVars.iconColor} />
            </div>
        </StyledDropdown>
    );
};

const StyledDropdown = styled('div')<Pick<ComponentProps, 'styles'>>`
    ${SPECIFICITY_WRAP} {
        width: 100%;
        display: flex;
        flex-direction: column;
        * {
            user-select: none;
        }
        .dropdown-title {
            text-align: start;
            margin-block-end: 4px;
            line-height: 22px;
            margin-top: 0;
            font-size: 16px;
            ${({ styles = {} }) => getBreakpointsStyle(styles.title)}
        }
        .dropdown-component {
            position: relative;
            cursor: pointer;
            width: 100%;
            height: 30px;
            max-height: 40px;
            border: 0px solid;

            .dropdown {
                width: 100%;
                height: 100%;
                margin: 0;
                background: inherit;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding-inline-start: 8px;
                padding-inline-end: 8px;
                border: 1px solid ${cssVars.borderColor};
                border-radius: 3px;
                outline: none;
                box-shadow: none;
                line-height: normal;
                ${({ styles = {} }) => getBreakpointsStyle(styles.fields)}

                &[data-placeholder-view='true'] {
                    color: ${cssVars.secondaryTextColor};
                }
                &[data-open='true'] {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }
    }
`;
export { DropdownProps };
