import { WidgetSchema } from 'types';
import {
    hydrateComponent,
    renderObserverComponent,
} from './infra/renderers/clientRenderers';
import { shouldHydrateWidgetOnRuntime } from './infra/renderers/utils';

export const hydrate = (
    widget: WidgetSchema,
    {
        observer = false,
        markupId = widget.id,
        asyncHydration = !!window.requestIdleCallback,
    }: { observer?: boolean; markupId?: string; asyncHydration?: boolean } = {}
): void => {
    const callback = () => {
        hydrateComponent({ ...widget, observer }, { markupId });
    };
    if (asyncHydration) {
        requestIdleCallback(callback);
    } else {
        callback();
    }
};

export const hydrateObserverWidget = (widget: WidgetSchema): void => {
    hydrate(widget, { observer: true });
};

export const hydrateAll = (widgets: WidgetSchema[] = []) => {
    widgets.forEach((widget) => hydrate(widget));
};

export const hydrateAllObserverWidgets = (widgets: WidgetSchema[] = []) => {
    widgets.forEach((data) => hydrateObserverWidget(data));
};

export const renderObserver = ({
    id,
    type,
    props,
    model,
}: WidgetSchema): void => {
    renderObserverComponent({ id, type, props, model, observer: true });
};

export const shouldHydrateOnRuntime = (widgets: WidgetSchema[]): boolean => {
    return widgets.some((widget) => shouldHydrateWidgetOnRuntime(widget));
};
