import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { CollectionItem } from '../types';
import StyledLink from 'client/widget-components/basicComponents/StyledLink';

interface SearchItemProps {
    href?: string;
    styles?: {
        dropdownItemHover?: ResponsiveStylesProp;
    };
    grabId?: string;
    item: CollectionItem;
}

export const SearchItem: FC<SearchItemProps> = ({
    href = '',
    styles,
    grabId,
    item,
}) => {
    return (
        <StyledLink
            styles={[
                itemDefaultStyles,
                nestResponsiveStyles(
                    mergeResponsiveStyles([
                        itemHoverDefaultStyles,
                        styles?.dropdownItemHover,
                    ]),
                    {
                        pseudoSelector: 'hover',
                    }
                ),
            ]}
            domAttrs={{
                href,
            }}
            grabId={grabId}
        >
            {item.data.name}
        </StyledLink>
    );
};

const itemDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'block',
        textDecoration: 'none',
        color: 'inherit',
        padding: '5px 40px',
    },
};

const itemHoverDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        background: '#F4F4F4',
    },
};
