import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DropdownProps } from '../types';
import { SPECIFICITY_WRAP } from '../../../responsive-styles/deprecated/styled-components-utils';
import useEditor from '../../../customHooks/useEditor';

const StyledNativeSelect = styled.select`
    ${SPECIFICITY_WRAP} {
        appearance: none;
    }
`;

const NativeDropdown: React.FC<DropdownProps> = ({
    defaultValue,
    placeholder,
    options = [],
    onChange,
    className,
}) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const { insideEditor } = useEditor();

    const [selectedValue, setSelectedValue] = useState(
        defaultValue?.value || ''
    );

    useEffect(() => {
        const element = selectRef.current;
        if (element) {
            const handleClick = (event: Event) => {
                if (insideEditor()) {
                    event.preventDefault();
                }
            };
            element.addEventListener('mousedown', handleClick);
            return () => {
                element.removeEventListener('mousedown', handleClick);
            };
        }
    }, []);

    const onSelect = useCallback(
        ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
            const selected = options.find(
                ({ value }) => value === target.value
            );
            if (selected) {
                setSelectedValue(target.value);
                onChange?.(selected);
            }
        },
        []
    );

    return (
        <StyledNativeSelect
            ref={selectRef as any}
            className={className}
            required
            value={selectedValue}
            onChange={onSelect}
            data-placeholder-view={!selectedValue}
            data-auto='dropdown-component'
        >
            <option value='' disabled hidden>
                {placeholder}
            </option>
            {options.map(({ value, label, disabled }) => (
                <option
                    value={value}
                    key={value}
                    disabled={disabled}
                    data-auto='select-option'
                >
                    {label}
                </option>
            ))}
        </StyledNativeSelect>
    );
};

export default NativeDropdown;
