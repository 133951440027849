import React from 'react';
import styled from 'styled-components';

interface ArrowIconProps {
    color?: string;
}

const width = '16px';

const ArrowSVG = styled.span`
    height: 100%;
    width: ${width};
    position: absolute;
    top: 0;
    inset-inline-start: calc(100% - ${width});
    margin-inline-start: -4px;
    display: grid;
    align-items: center;
`;
function ArrowIcon({ color }: ArrowIconProps) {
    return (
        <ArrowSVG className='arrow-icon'>
            <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.5769 5.5L12.5 6.4375L8.5 10.5L4.5 6.4375L5.42308 5.5L8.5 8.625L11.5769 5.5Z'
                    fill={color || '#000'}
                />
            </svg>
        </ArrowSVG>
    );
}

export default ArrowIcon;
