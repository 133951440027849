export function encodeUniqueKey(variation: { [key: string]: string } | string) {
    try {
        return btoa(
            typeof variation === 'string'
                ? variation
                : JSON.stringify(variation)
        );
    } catch (err) {
        return '{}';
    }
}

export function decodeUniqueKey(base64Key: string) {
    try {
        return JSON.parse(atob(base64Key).toString() || '{}');
    } catch (err) {
        return {};
    }
}
