import { configure, makeAutoObservable, toJS } from 'mobx';
import { GeneralWidgetProps } from 'types';
import { translateStyleObjToReactStyleObj } from '@duda-co/style';
import { decodeUniqueKey } from 'client/widget-components/ecom/stores/utils';
import { logger } from '@shared-services/log-service';
import { postToEditor } from 'client/editorMsApi/postToEditor';
import { featureFlags } from '@shared-services/feature-flags';

configure({ isolateGlobalState: true });

declare global {
    var propsStore: PropsStoreCollection;
}

function isObject(thing: unknown): thing is Record<string, unknown> {
    return typeof thing === 'object' && !Array.isArray(thing) && thing !== null;
}

function customMerge<T extends object>(obj1: T, obj2: T): T {
    const keys = new Set<keyof T>(
        Object.keys({ ...obj1, ...obj2 }) as Array<keyof T>
    );
    const mergedObj: any = {};
    keys.forEach((key) => {
        const aVal = obj1[key];
        const bVal = obj2[key];
        if (isObject(aVal) && isObject(bVal)) {
            mergedObj[key] = customMerge(aVal, bVal);
        } else {
            mergedObj[key] = bVal ?? aVal;
        }
    });
    return mergedObj as T;
}

const getWidgetModelFromWidgetElement = (widgetId: string) => {
    const widgetElement = globalThis?.document?.getElementById(widgetId);
    const base64Model = widgetElement?.dataset?.model;
    return decodeUniqueKey(base64Model as string);
};

class WidgetStore {
    private _legacyWidget: boolean = true;
    private _model: GeneralWidgetProps;
    private _props: GeneralWidgetProps;
    private _initialProps?: GeneralWidgetProps;

    constructor(
        model: GeneralWidgetProps = {},
        widgetProps: GeneralWidgetProps = {}
    ) {
        this._model = model;
        this._props = widgetProps;
        makeAutoObservable(this);
    }

    get model(): GeneralWidgetProps {
        if (!this._legacyWidget) {
            const msg = 'model accessed in non legacy widget';
            console.error(msg);

            logger.debug({
                msg,
            });
        }
        return this._model;
    }

    get modelForPersistance(): GeneralWidgetProps {
        return toJS(this.model);
    }

    get props(): GeneralWidgetProps {
        if (this._legacyWidget) {
            const props = this._props;
            props._styles = translateStyleObjToReactStyleObj(
                this.model._styles
            );
            return props;
        }
        return this._props;
    }

    getInitialJSProps = () => {
        return toJS(this._initialProps);
    };

    getJSProps = () => {
        return toJS(this._props);
    };

    setProps(newProps: GeneralWidgetProps) {
        this._props = newProps;
    }

    jsModel(): GeneralWidgetProps {
        return toJS(this._model);
    }

    mergeProps(newProps: GeneralWidgetProps) {
        this._props = customMerge({ ...this.props }, { ...newProps });
    }

    clearProps() {
        this._props = {};
    }

    mergeModel(newModel: GeneralWidgetProps) {
        this._model = customMerge({ ...this._model }, { ...newModel });
    }

    clearModel() {
        this._model = {};
    }

    clearModelStyle() {
        this._model = { ...this._model, _styles: {} };
    }

    set legacyWidget(val: boolean) {
        this._legacyWidget = val;
    }

    /**
     * resetting props - needed for connect to data flow.
     */
    setInitialProps(initialProps: GeneralWidgetProps, widgetId: string) {
        this._initialProps = initialProps;

        if (featureFlags.getBoolean('runtime.ssr.add.render')) {
            this.setProps(initialProps);
        } else {
            this.mergeProps(initialProps);
        }

        postToEditor({
            type: 'widget-props-store-initial-props-updated',
            widgetId,
        });
    }
}

class PropsStoreCollection {
    widgetsStoresMap = new Map<string, WidgetStore>();

    getWidgetStore(widgetId: string): WidgetStore {
        let widgetStore: WidgetStore | undefined =
            this.widgetsStoresMap.get(widgetId);

        if (!widgetStore) {
            const widgetStoreModel = getWidgetModelFromWidgetElement(widgetId);

            widgetStore = new WidgetStore(
                widgetStoreModel as GeneralWidgetProps
            );
            this.widgetsStoresMap.set(widgetId, widgetStore);
        }

        return widgetStore;
    }

    // @deprecated
    getWidgetData = this.getWidgetStore;
}

export { WidgetStore };

globalThis.propsStore = globalThis.propsStore || new PropsStoreCollection();
export default globalThis.propsStore;
