import React, { useEffect } from 'react';

export enum EventType {
    CLICK = 'click',
    SCROLL = 'scroll',
    WHEEL = 'wheel',
}

interface UseOnOutsideEventProps {
    handler: () => void;
    ref: React.RefObject<HTMLElement>;
    events?: Array<EventType>;
}
const useOnOutsideEvent = ({
    handler,
    ref,
    events = [EventType.CLICK],
}: UseOnOutsideEventProps) => {
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const eventsToListen = [...new Set(events)];
        const listener = (event: Event) => {
            if (ref.current?.contains(event.target as HTMLElement)) {
                return;
            }
            handler();
        };
        eventsToListen.forEach((event) => {
            window.addEventListener(event, listener);
        });
        return () => {
            eventsToListen.forEach((event) => {
                window.removeEventListener(event, listener);
            });
        };
    }, []);
};

export default useOnOutsideEvent;
