import { MediaBaseData } from 'client/widget-components/CommonModelTypes';
import React, { FC } from 'react';
import { Div, Img } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import Video from 'client/widget-components/basicComponents/Video';
import { context } from '@shared-services/context-service';

interface Props extends MediaBaseData {
    styles?: ResponsiveStylesProp;
    renderPlaceholderOnServer?: boolean;
}

const dataGrab = 'slide-media';

/**
 * TODO: organize lazy optimization strategy code.
 * Widget should properly know if it is aboveFold or not, and put this in a React context.
 * Widget can also decide on the optimizationStrategy based on viewport etc, but it will be determined by default by the React.context.
 */
export const SlideMedia: FC<Props> = ({
    vidSrc,
    imgSrc,
    alt,
    type,
    styles,
    lazy,
    renderPlaceholderOnServer,
}) => {
    if (renderPlaceholderOnServer && context?.isServer) {
        return <Div styles={styles} data-grab={dataGrab} />;
    }

    if (type === 'VIDEO') {
        return (
            <Video
                data-grab={dataGrab}
                src={vidSrc}
                poster={imgSrc}
                controls={false}
                autoPlay
                loop
                muted
                playsInline
                styles={styles}
            />
        );
    }
    return (
        <Img
            data-grab={dataGrab}
            src={imgSrc}
            alt={alt}
            styles={styles}
            loading={lazy ? 'lazy' : undefined}
        />
    );
};
