import React from 'react';
import { FilterableField, FilterIDS, FilterTypes } from '../utils/types';
import FilterItem from './FilterItem';
import { useFilterSortContext } from '../utils/filterSortContext';

interface FilterListProps {
    values: Array<FilterableField>;
    currentSelection: {
        [filterName: string]: FilterableField;
    };
    onChange: (filterValue: FilterableField) => void;
}
function FiltersList({ values, currentSelection, onChange }: FilterListProps) {
    const { inDesignMode, hasProducts } = useFilterSortContext();
    return (
        <>
            {values.map((filterField) => {
                if (filterField.disabled && !inDesignMode) {
                    return null;
                }
                const filterId = filterField.fieldId;
                const filterType = filterField.filterType || FilterTypes.ONE_OF;
                const filterSelectedValues =
                    currentSelection[filterId]?.selectedValues || undefined;

                if (
                    filterId === FilterIDS.CATEGORY_ID &&
                    Object.keys(filterField.filtersData).length === 0
                ) {
                    return null;
                }
                if (filterId === FilterIDS.PRICE && !hasProducts) {
                    return null;
                }

                return (
                    <FilterItem
                        key={filterId}
                        item={filterField}
                        type={filterType}
                        currentValues={filterSelectedValues}
                        onChange={(selectedValues) => {
                            onChange({
                                ...filterField,
                                filterType,
                                selectedValues,
                            });
                        }}
                    />
                );
            })}
        </>
    );
}

export default FiltersList;
