import withLink, { LinkProps } from '../../hocs/withLink';
import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
} from '../../responsive-styles/deprecated/styled-components-utils';

const globalClassNames = {
    button: 'align-center dmButtonLink dmWidget dmWwr default dmOnlyButton dmDefaultGradient',
    iconWrapper: 'iconBg',
    icon: 'icon hasFontIcon',
};

type StyleProperty =
    | 'button'
    | 'buttonHover'
    | 'text'
    | 'textHover'
    | 'icon'
    | 'iconHover'
    | 'disabledButton'
    | 'disabledText'
    | 'disabledIcon';

/** @deprecated */
export interface DeprecatedButtonProps {
    styles?: Partial<
        Record<StyleProperty, DeprecatedResponsiveStyles | CSSProperties>
    >;
    linkProps?: LinkProps;
    className?: string;
    buttonLayout?: string;
    iconProps?: {
        showIcon: boolean;
        iconClassName: string;
    };
    isDisabled?: boolean;
    opacityWhenDisabled?: boolean;
}

type InnerProps = Omit<DeprecatedButtonProps, 'isDisabled'>;

function getStyle(key: StyleProperty) {
    return ({ styles = {} }: DeprecatedButtonProps) =>
        getBreakpointsStyle(styles[key]);
}

const StyledButton = styled.div<InnerProps>`
    #dm #site_content &&&& {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        outline: none;
        overflow: initial;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        align-items: center;
        ${getStyle('button')};

        .text {
            display: inline-block;
            flex: 1;
            font-size: 15px;
            ${getStyle('text')};
        }

        .iconBg {
            display: inline !important;
            position: absolute;
            left: 0;
            width: 50px;

            .icon {
                ${getStyle('icon')};
            }

            .icon-runtime-test:before {
                // for test cases
                content: '☂';
            }
        }

        &:hover:not(.isDisabled) {
            ${getStyle('buttonHover')};

            .text {
                ${getStyle('textHover')};
            }

            .iconBg .icon {
                ${getStyle('iconHover')};
            }
        }

        &.isDisabled {
            // disable all hover effects
            pointer-events: none;

            opacity: ${({ opacityWhenDisabled = false }) =>
                opacityWhenDisabled ? 0.6 : 1};
            ${getStyle('disabledButton')};

            .text {
                ${getStyle('disabledText')};
            }

            .iconBg .icon {
                ${getStyle('disabledIcon')};
            }
        }
    }
`;

/**
 * @deprecated
 * use Button instead
 */
export const DeprecatedButton: React.FC<
    PropsWithChildren<DeprecatedButtonProps>
> = ({
    styles,
    children,
    linkProps,
    iconProps,
    className = '',
    buttonLayout,
    isDisabled = false,
    ...props
}) => {
    const ButtonContent = (buttonProps: DeprecatedButtonProps) => {
        return (
            <StyledButton
                {...buttonProps}
                styles={styles}
                data-display-type='block'
                className={`${
                    globalClassNames.button
                } ${className} ssr-button ${isDisabled ? 'isDisabled' : ''}`}
                data-buttonstyle={buttonLayout}
            >
                {iconProps?.showIcon && (
                    <span className={globalClassNames.iconWrapper}>
                        <span
                            className={`${globalClassNames.icon} ${iconProps.iconClassName}`}
                        />
                    </span>
                )}
                <span className='text'>{children}</span>
            </StyledButton>
        );
    };

    const LinkableButton = withLink(ButtonContent, linkProps);

    return linkProps ? (
        <LinkableButton {...props} />
    ) : (
        <ButtonContent {...props} />
    );
};

export default DeprecatedButton;
