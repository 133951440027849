import React, { useRef, useState } from 'react';
import { DropdownOption, DropdownProps } from '../types';
import useOnOutsideEvent, {
    EventType,
} from '../../../customHooks/useOnOutsideEvent';
import DropdownList from './DropdownList';
import styled from 'styled-components';
import useEditor from '../../../customHooks/useEditor';

const StyledDropdown = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    .dropdown-header {
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        z-index: 2;
        background: transparent;
    }
`;

const Dropdown: React.FC<DropdownProps> = ({
    defaultValue,
    placeholder,
    options = [],
    onChange,
    className,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
        defaultValue || null
    );
    const { insideEditor } = useEditor();

    const containerRef = useRef<HTMLDivElement>(null);

    useOnOutsideEvent({
        handler: () => setIsOpen(false),
        ref: containerRef,
        events: [EventType.WHEEL, EventType.CLICK],
    });

    const onOptionSelected = (selectedValue: DropdownOption) => {
        onChange?.(selectedValue);
        setSelectedOption(selectedValue);
        setIsOpen(false);
    };

    const toggleMenu = () => {
        if (!insideEditor()) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <StyledDropdown
            ref={containerRef}
            className={className}
            data-placeholder-view={!selectedOption}
            data-open={isOpen}
            onClick={toggleMenu}
            data-auto='dropdown-component'
        >
            <span className='dropdown-header'>
                {selectedOption?.label || placeholder}
            </span>
            {isOpen && !!options.length && (
                <DropdownList
                    parentAnchor={containerRef.current}
                    options={options}
                    onSelect={onOptionSelected}
                    selectedValue={selectedOption}
                />
            )}
        </StyledDropdown>
    );
};

export default Dropdown;
