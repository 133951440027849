import React, { useCallback, useEffect, useState } from 'react';
import { RangeSliderProps } from './types';
import { RangeInput } from './RangeInput';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { Div } from 'client/widget-components/basicComponents';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { debounce } from 'client/utils';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';

const RangeSlider = ({
    trackDataGrab = 'range-slider-track',
    ...props
}: RangeSliderProps) => {
    const { minRange = 0, currentValues } = props;
    const [rangeValues, setRangeValues] = useState({
        start: currentValues?.start || props.min,
        end: currentValues?.end || props.max,
    });
    const onChange = useCallback(debounce(props.onChange, 300), []);

    useEffect(() => {
        setRangeValues({
            start: currentValues?.start || props.min,
            end: currentValues?.end || props.max,
        });
    }, [currentValues]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const min = parseInt(event.target.min, 10);
        const max = parseInt(event.target.max, 10);
        const value = event.target.valueAsNumber;

        const updatedValues = { ...rangeValues, [name]: value };
        let pointsDistance = Math.abs(
            rangeValues[name === 'start' ? 'end' : 'start'] - value
        );
        const isStartExceedsEnd =
            (name === 'start' && value > rangeValues.end) ||
            (name === 'end' && value < rangeValues.start);
        if (isStartExceedsEnd) {
            pointsDistance *= -1;
        }
        if (minRange > pointsDistance) {
            const diffRange = minRange - pointsDistance;
            if (name === 'start') {
                updatedValues.end = Math.min(rangeValues.end + diffRange, max);
            } else if (name === 'end') {
                updatedValues.start = Math.max(
                    rangeValues.start - diffRange,
                    min
                );
            }
        }
        setRangeValues(updatedValues);
        onChange(updatedValues);
    };

    const thumbStyles = mergeResponsiveStyles([
        props.trackStyles,
        props.thumbStyles,
    ]);

    return (
        <Div data-auto='range-slider'>
            <Div
                styles={[valuesWrapperDefaultStyles, props.labelsWrapperStyles]}
            >
                <Text
                    styles={[labelDefaultStyles, props.labelStyles]}
                    data-auto='min-input-label'
                    tag={TextDomTag.paragraph}
                >
                    <span>{rangeValues.start}</span>
                    {props.sign && <span>{props.sign}</span>}
                </Text>
                <Text
                    styles={[labelDefaultStyles, props.labelStyles]}
                    data-auto='max-input-label'
                    tag={TextDomTag.paragraph}
                >
                    <span>{rangeValues.end}</span>
                    {props.sign && <span>{props.sign}</span>}
                </Text>
            </Div>
            <Div styles={inputWrapperDefaultStyles}>
                <Div
                    styles={[
                        trackWrapperDefaultStyles,
                        nestResponsiveStyles(
                            mergeResponsiveStyles([
                                commonTrackDefaultStyles,
                                props.trackStyles,
                            ]),
                            {
                                innerSelector: `[data-grab=${trackDataGrab}]`,
                            }
                        ),
                    ]}
                >
                    <Div
                        styles={trackDefaultStyles}
                        data-grab={trackDataGrab}
                    />
                    {!props.hideRangeTrack && (
                        <Div
                            data-grab={trackDataGrab}
                            styles={getRangeTrackStyles({
                                ...rangeValues,
                                min: props.min,
                                max: props.max,
                            })}
                        />
                    )}
                </Div>
                <RangeInput
                    name='start'
                    min={props.min}
                    max={props.max}
                    value={rangeValues.start}
                    onChange={handleChange}
                    data-auto='range-slider-start-input'
                    thumbStyles={thumbStyles}
                />
                <RangeInput
                    name='end'
                    min={props.min}
                    max={props.max}
                    value={rangeValues.end}
                    onChange={handleChange}
                    data-auto='range-slider-end-input'
                    thumbStyles={thumbStyles}
                />
            </Div>
        </Div>
    );
};
const inputWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
};

const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
    },
};

const valuesWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBlockEnd: '12px',
    },
};

function getRangeTrackStyles({
    start,
    end,
    min,
    max,
}: Record<string, number>): ResponsiveStylesStructured {
    const totalDistance = max - min;
    const startDistance = ((start - min) / totalDistance) * 100;
    const endDistance = ((end - start) / totalDistance) * 100;
    return {
        [RuntimeMediaQuery.COMMON]: {
            height: '100%',
            position: 'absolute',
            borderRadius: '24px',
            left: `${startDistance}%`,
            width: `${endDistance}%`,
            top: 0,
        },
    };
}

const trackWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundColor: 'transparent',
        position: 'relative',
        width: '100%',
    },
};

const trackDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        opacity: 0.5,
        width: '100%',
    },
};
const commonTrackDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '2px',
        backgroundColor: '#000',
        borderRadius: '6px',
    },
};

export default RangeSlider;
export { type RangeSliderProps };
