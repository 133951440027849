import React, { CSSProperties, useMemo } from 'react';
import {
    upgradeStyles,
    DeprecatedWidgetStyles,
} from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import withAddToCart from './withAddToCart';
import { RuntimeWidget } from 'client/types';
import {
    DeprecatedButton,
    DeprecatedButtonProps,
} from 'client/widget-components/components/DeprecatedButton';

export interface RuntimeAddToCartProps {
    text?: string;
    disabledText?: string;
    iconProps?: {
        ['selected-icon']: { classname: string; categories: string[] };
        ['show-icon']: boolean;
    };
    _styles?: CSSProperties & DeprecatedButtonProps['styles'];
    isDisabled: boolean;
}

export const RuntimeAddToCart: RuntimeWidget<RuntimeAddToCartProps> = ({
    text,
    disabledText,
    _styles,
    iconProps,
    isDisabled = false,
    ...rest
}: RuntimeAddToCartProps) => {
    const showIcon = !!iconProps?.['show-icon'];
    const rulesStyles = useMemo(() => {
        return upgradeStyles(_styles as DeprecatedWidgetStyles).rules;
    }, [_styles]);

    return (
        <DeprecatedButton
            styles={rulesStyles || {}}
            buttonLayout={showIcon ? 'FLAT_ROUND_ICON' : 'ROUND_SIDES'}
            className='add-to-cart-button'
            {...(showIcon && {
                iconProps: {
                    showIcon,
                    iconClassName: iconProps['selected-icon'].classname,
                },
            })}
            isDisabled={isDisabled}
            opacityWhenDisabled
            {...rest}
        >
            {isDisabled && disabledText ? disabledText : text}
        </DeprecatedButton>
    );
};

export default withAddToCart(RuntimeAddToCart);
