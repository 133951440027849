import styled from 'styled-components';
import { ResponsiveStylesProp } from '../responsive-styles/responsiveStylesService';
import { toCSSObject } from '../responsive-styles/toCSSObject';

interface Props {
    styles?: ResponsiveStylesProp;
}

const GenericStyledTag = styled.div<Props>(({ styles }) =>
    toCSSObject(styles || [])
);

export const Div = GenericStyledTag;

export const Span = GenericStyledTag.withComponent('span');

export const Img = GenericStyledTag.withComponent('img');
export const Label = GenericStyledTag.withComponent('label');
export const Svg = GenericStyledTag.withComponent('svg');

export function getResponsiveComponent(
    type: 'div' | 'span',
    increaseSpecificity: boolean = false
) {
    const StyledTag = styled.div<Props>(({ styles }) =>
        toCSSObject(styles, increaseSpecificity)
    );
    return StyledTag.withComponent(type);
}
