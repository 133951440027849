import React, { MouseEvent, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import cssVars from '../../../cssVariables';
import { DropdownListProps, DropdownOption } from '../types';

const DropDownListContainer = styled('ul')`
    position: fixed;
    z-index: 100;
    border: 1px solid ${cssVars.borderColor};
    border-radius: 3px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 4.5px 9px -3.375px rgb(24 39 75 / 4%), 0px 3.375px 4.5px -3.375px rgb(24 39 75 / 8%);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-weight: 500;
    max-height: 200px;
    color: ${({ theme = {} }: any) => theme.color || cssVars.primaryTextColor};
    background-color: ${({ theme }) =>
        theme.backgroundColor || cssVars.bgWhite};
    ${({ theme }) => ({ ...(theme.list || {}) })};
    ${({ theme }) => ({ ...(theme.border || {}) })};
    
    &&{
        border-top: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
        li{
            list-style: none;
            padding: 6px 8px 6px 10px;
            cursor: pointer;
            &:hover:not([data-disabled="true"]) {
                background-color: ${cssVars.headerColor};
            }
            &[data-selected="true"]{
               font-weight: 600;
            }
            &[data-disabled="true"] {
               color: #cccccc82;
               cursor: initial;
            }
    }
}`;

const DropdownList = ({
    options,
    onSelect,
    parentAnchor,
    selectedValue,
}: DropdownListProps) => {
    const listRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        function adjustPosition() {
            if (parentAnchor && listRef.current) {
                const { bottom, width, left } =
                    parentAnchor.getBoundingClientRect();
                listRef.current.style.top = `${bottom}px`;
                listRef.current.style.width = `${width}px`;
                listRef.current.style.left = `${left}px`;
            }
        }

        adjustPosition();
        window.addEventListener('scroll', adjustPosition);
        return () => {
            window.removeEventListener('scroll', adjustPosition);
        };
    }, []);

    const onOptionClick = (event: MouseEvent, option: DropdownOption) => {
        if (!option.disabled) {
            onSelect(option);
        }
        // in order to prevent the menu from closing
        event.stopPropagation();
    };
    return (
        <DropDownListContainer ref={listRef as any} data-auto='dropdown-list'>
            {options.map((option: DropdownOption) => (
                <li
                    key={option.label}
                    onClick={(event) => onOptionClick(event, option)}
                    data-disabled={!!option.disabled}
                    data-selected={option.value === selectedValue?.value}
                    data-auto='select-option'
                >
                    {option.label || option.value}
                </li>
            ))}
        </DropDownListContainer>
    );
};
export default DropdownList;
