import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Div } from 'client/widget-components/basicComponents';

export interface RuntimeProductPriceProps {
    currency: string;
    price: string;
    salePrice?: string;
    frequency?: string;
    _styles?: {
        price: ResponsiveStylesProp;
        salePrice: ResponsiveStylesProp;
        frequency: ResponsiveStylesProp;
    };
}

const RuntimeProductPrice: FC<RuntimeProductPriceProps> = ({
    currency,
    price,
    salePrice,
    frequency,
    _styles: styles,
}) => {
    return (
        <Div data-auto='product-price-widget' styles={containerDefaultStyles}>
            {salePrice && (
                <Div
                    data-grab='product-sale-price'
                    styles={[salePriceDefaultStyles, styles?.salePrice]}
                >
                    {currency}
                    {salePrice}
                </Div>
            )}

            <Div data-grab='product-price' styles={styles?.price}>
                {currency}
                {price}
            </Div>

            {frequency && (
                <Div data-grab='product-frequency' styles={styles?.frequency}>
                    /{frequency}
                </Div>
            )}
        </Div>
    );
};

const containerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'baseline',
    },
};

const salePriceDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textDecoration: 'line-through',
        paddingInlineEnd: 8,
    },
};

const RuntimeProductPriceWidget = withErrorBoundary({
    Comp: RuntimeProductPrice,
    componentName: 'RuntimeProductPrice',
});

export default RuntimeProductPriceWidget;
