import {
    hydrate,
    hydrateObserverWidget,
    hydrateAll,
    hydrateAllObserverWidgets,
    renderObserver,
    shouldHydrateOnRuntime,
} from './api';

(window as any).SSRRuntime = {
    RuntimeReactHelpers: {
        hydrate,
        hydrateObserverWidget,
        hydrateAll,
        hydrateAllObserverWidgets,
        renderObserver,
        shouldHydrateOnRuntime,
    },
};

interface Window {
    getDeferred?: (name: string) => void | { resolve: () => void };
}

if (typeof window !== 'undefined') {
    (window as Window)?.getDeferred?.('ssrLibrariesLoaded')?.resolve();
}
