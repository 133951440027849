import React, { InputHTMLAttributes } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

import { Input } from 'client/widget-components/basicComponents/Input/Input';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from 'client/widget-components/responsive-styles/responsiveStylesService';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    thumbStyles?: ResponsiveStylesProp;
}

export function RangeInput(props: Props) {
    const thumbStyles = mergeResponsiveStyles([
        thumbDefaultStyles,
        props.thumbStyles,
        thumbOverridesMediaQueryStyles,
    ]);
    return (
        <Input
            type='range'
            styles={[
                defaultInputStyles,
                nestResponsiveStyles(
                    {
                        [RuntimeMediaQuery.COMMON]: {
                            height: '100%',
                        },
                    },
                    {
                        innerSelector: '&::-webkit-slider-runnable-track',
                    }
                ),
                nestResponsiveStyles(thumbStyles, {
                    innerSelector: '&&::-webkit-slider-thumb',
                }),
                nestResponsiveStyles(thumbStyles, {
                    innerSelector: '&::-moz-range-thumb',
                }),
            ]}
            {...props}
        />
    );
}

const defaultInputStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
        height: '200%',
        width: '100%',
        position: 'absolute',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        pointerEvents: 'none',
        cursor: 'pointer',
        minHeight: '8px',
    },
};

const thumbDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        WebkitAppearance: 'none',
        backgroundColor: '#000',
        pointerEvents: 'all',
        borderRadius: '50%',
        aspectRatio: '1/1',
        width: 'unset',
        border: '0',
    },
};

const thumbOverridesMediaQueryStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '100%',
    },
    [RuntimeMediaQuery.TABLET]: {
        height: '100%',
    },
    [RuntimeMediaQuery.DESKTOP]: {
        height: '100%',
    },
    [RuntimeMediaQuery.MOBILE]: {
        height: '100%',
    },
};
