import React from 'react';
import { hydrate, render } from 'react-dom';
import {
    GenerateComponentArgs,
    getComponentByType,
    getWrapperId,
    populateWidgetStore,
} from './utils';
import ObserverWidget from './ObserverWidget';
import { featureFlags } from '@shared-services/feature-flags';

/**
 * This is being called only on the client side, to hydrate the widget
 */
export function hydrateComponent(
    { type, props, id, observer }: GenerateComponentArgs,
    { markupId = id }: { markupId?: string }
): void {
    props._styles = props?._styles || {};
    const Component = getComponentByType(type);
    if (Component && id && markupId && document.getElementById(id)) {
        if (observer) {
            populateWidgetStore(id as string, props, type);
        }
        const reactResult = observer ? (
            <ObserverWidget Component={Component} widgetId={id} />
        ) : (
            <Component {...props} />
        );
        const idToRenderTo = featureFlags.getBoolean(
            'runtime.ssr.initial-props-in-data-attribute'
        )
            ? getWrapperId(id)
            : id;
        const container = document.getElementById(idToRenderTo);
        hydrate(reactResult, container);
    }
}

/**
 * @todo
 * TODO: Remove flow after completing change to align to server render on adding new widget.
 * This can be used to client-side render a new widget (without SSR)
 */
export const renderObserverComponent = ({
    type,
    props,
    id,
}: GenerateComponentArgs): void | string | null => {
    const Component = getComponentByType(type);
    if (Component) {
        if (id && document.getElementById(id)) {
            // Populate the propsStore with the received props
            populateWidgetStore(id as string, props, type);
            render(
                <ObserverWidget Component={Component} widgetId={id} />,
                document.getElementById(id)
            );
        }
    }
};
